export default class ShiftOrder {
  affiliationId: string;
  employeeId: string;
  order: string;
  constructor(affiliationId: string, employeeId: string, order: string) {
    this.affiliationId = affiliationId;
    this.employeeId = employeeId;
    this.order = order;
  }
}
